.helpNavbar .Mui-selected {
    background-color: #F26122;
    color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.helpNavbar .MuiTab-root{
    text-transform: none !important;
    font-weight: 600;
    font-size: 12.5px;
    max-width: 300px;
    padding-left: 25px;
}

p, h2 {
    font-family: Poppins-Regular;
    src: url(../../assets/fonts/Poppins/Poppins-Regular.ttf);
}